import { lazy, Suspense, useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";

import Loader from "./components/Loader/Loader";

const Header = lazy(() => import("./layout/header/Header"));
const RightSidebar = lazy(() => import("./layout/rightSidebar/RightSidebar"));
const LeftSidebar = lazy(() => import("./layout/leftSidebar/LeftSidebar"));

const Homepage = lazy(() => import("./pages/Homepage/Homepage"));
const ThemePage = lazy(() => import("./pages/ThemePage/ThemePage"));
const StoryPage = lazy(() => import("./pages/StoryPage/StoryPage"));
const WriteStoryPage = lazy(() =>
  import("./pages/WriteStoryPage/WriteStoryPage")
);

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);

  const authStatus = Cookies.get("authenticated");
  useEffect(() => {
    if (authStatus === "1") {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [authStatus]);
  //authStatus === "1" TRUE
  return (
    <>
      <Suspense fallback={<p>Betöltés folyamatban...</p>}>
        <div className="app">
          <Header authenticated={authenticated} />
          <div className="content-wrapper">
            <aside className="sidebar">
              <LeftSidebar />
            </aside>
            <main className="main-content">
              <Routes>
                <Route
                  path="/:theme/story/:slug"
                  element={<StoryPage authenticated={authenticated} />}
                />
                <Route
                  path="/write-story"
                  element={<WriteStoryPage authenticated={authenticated} />}
                />
                <Route
                  path="/:theme"
                  element={<ThemePage authenticated={authenticated} />}
                />
                <Route
                  path="/"
                  exact
                  element={<Homepage authenticated={authenticated} />}
                />
              </Routes>
            </main>
            <aside className="sidebar">
              <RightSidebar />
            </aside>
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default App;
