import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/styles/app.global.css";
import "./assets/styles/app.layout.css";
import "./assets/styles/app.stories.css";
import "./assets/styles/app.comments.css";
import "./assets/styles/app.theme.page.css";
import "./assets/styles/app.write.story.page.css";
import "./assets/styles/app.story.page.css";
import "./assets/styles/app.report.css";

import "react-quill/dist/quill.snow.css";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./context/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
