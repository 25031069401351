import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
// import usersReducer from "./usersSlices/usersReducers";
// import galleryReducer from "./gallerySlices/gallerySlices";
// import aboutUsReducers from "./aboutUsSlices/aboutUsReducers";
// import productsReducers from "./productsSlices/productsReducers";
// import storage from "redux-persist/lib/storage";
// import { persistReducer } from "redux-persist";

// const persistConfig = {
//   key: "root",
//   version: 1,
//   storage,
// };

const reducer = combineReducers({
  auth: authReducer,
  // user: usersReducer,
  // gallery: galleryReducer,
  // aboutUs: aboutUsReducers,
  // product: productsReducers,
});

// const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  //   reducer: persistedReducer,
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
